<template>
  <v-autocomplete
    outlined
    :items="debrisTypes"
    :label="field.text"
    v-model="value"
    :item-text="getDebrisString"
    item-value="id"
    placeholder="Wybierz typ odpadu"
    :rules="validators"
    append-icon="mdi-chevron-down"
    dense
    clearable
    :filter="searchDebrisType"
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { searchDebrisType, getDebrisString } from '../../../utils'
import { mapState, mapActions } from 'vuex'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    ...mapState({
      debrisTypes: state => state.debrisTypes.items
    }),
    value: {
      get () {
        const currentValue = get(this.data, this.field.value, '')
        const suffix = this.field.value.split('.').pop()
        const currentObject = this.debrisTypes.find((type) => {
          return currentValue === type[suffix]
        })
        return currentObject ? currentObject.id : null
      },
      set (value) {
        const label =
          this.field.name === 'aggregateTypeId'
            ? 'debrisTypeId'
            : this.field.name
        this.$emit('update', { [label]: value || null })
      }
    },
    isBdoCardView () {
      return !!this.data.cardNumber
    },
  },
  mounted () {
    const { courseType } = this.data.courses?.[0] || {}
    const aggregate = this.isBdoCardView ? false : courseType !== 'Podstawienie'
    this.getDebrisTypes({ params: { aggregate } })
  },
  methods: {
    ...mapActions({
      getDebrisTypes: 'debrisTypes/getItems'
    }),
    searchDebrisType,
    getDebrisString
  }
}
</script>
